d<template>
  <div class="login" id="login_container">
    <img
      src="~images/home/logo.png"
      class="login-logo"
      alt=""
      @click="goHome"
    />
    <div class="login-right-box flex" v-if="isLogin">
      <div class="account-wrapper" v-if="isAccount">
        <div class="flex login-type-box">
          <div
            class="login-type-item"
            :class="{ 'login-type-active': loginIndex == 0 }"
            @click="changeLogin(0)"
          >
            账号密码登录
          </div>
          <div
            class="login-type-item"
            :class="{ 'login-type-active': loginIndex == 1 }"
            @click="changeLogin(1)"
          >
            验证码登录
          </div>
        </div>
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="flex"
        >
          <template v-if="loginIndex == 0">
            <el-form-item class="flex" prop="username">
              <el-input
                v-model="ruleForm.username"
                placeholder="请输入登录账号或手机号码"
              >
                <div slot="prefix" class="flex input-icon">
                  <img src="~image/login/account.png" alt="" />
                </div>
              </el-input>
            </el-form-item>
            <el-form-item class="flex" prop="password">
              <el-input
                v-model="ruleForm.password"
                :type="pwdType"
                autocomplete="off"
                placeholder="请输入密码"
                @keyup.enter.native="submitForm('ruleForm')"
              >
                <div slot="prefix" class="flex input-icon">
                  <img src="~image/login/pwd.png" alt="" class="pswd-img" />
                </div>
                <div class="flex-center" slot="suffix" @click.stop="changePwd">
                  <i class="el-icon-view"></i>
                </div>
              </el-input>
            </el-form-item>
            <div class="flex rember">
              <el-checkbox v-model="ruleForm.rememberMe">记住密码</el-checkbox>
              <div class="forget-pwd" @click="goforgotPwd">
                忘记密码
              </div>
            </div>
          </template>
          <template v-else>
            <el-form-item class="flex" prop="account">
              <el-input
                v-model="ruleForm.account"
                placeholder="请输入手机号码或邮箱"
              >
                <div slot="prefix" class="flex input-icon">
                  <img src="~image/login/account.png" alt="" />
                </div>
              </el-input>
            </el-form-item>
            <el-form-item class="code-box" prop="code">
              <el-input
                v-model="ruleForm.code"
                placeholder="请输入验证码"
                maxlength="6"
                @keyup.enter.native="submitForm('ruleForm')"
              >
                <div slot="prefix" class="flex input-icon">
                  <img src="~image/login/code.png" alt="" class="pswd-img" />
                </div>
              </el-input>
              <el-button
                type="primary"
                class="flex send-code"
                @click="sendLoginCode"
                :disabled="disLoginBtn"
                native-type="button"
                >{{ loginTxt }}</el-button
              >
            </el-form-item>
          </template>
          <el-form-item class="login-btn-box">
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              :loading="loading"
              >登录</el-button
            >
            <div
              class="login-register"
              :style="{
                'justify-content':
                  loginIndex == 1 ? 'space-between' : 'flex-start'
              }"
            >
              <div>
                没有账号？
                <span class="color_red" @click.stop="goRegister">立即注册</span>
              </div>
              <!-- <div
                  v-if="loginIndex == 1"
                  @click="changeCodeLogin"
                  class="login-code-text"
                >
                  {{ isPhoneLogin ? "邮箱登录" : "手机登录" }}
                </div> -->
            </div>
            <div class="login-icon-box">
              <div class="flex-center flex-columm" @click="loginCode('QQ')">
                <img src="~images/home/QQ.png" alt="" />
                <div class="login-icon">QQ登录</div>
              </div>
              <div
                class="flex-center flex-columm"
                style="margin-left:32px;"
                @click="loginCode('WX')"
              >
                <img src="~images/home/wx.png" alt="" />
                <div class="login-icon">微信登录</div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
// import { sendPhone } from "@/api/user";
import Cookies from "js-cookie";
import { validatEmail, isvalidPhone } from "@/utils/validate";
import { post } from "@/api/index";
import { webSocket } from "@/utils/webSocket";
import axios from "axios";
import { Message } from "element-ui";
export default {
  name: "loign",
  components: {},
  mixins: [webSocket],
  data() {
    const validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入账号"));
      } else {
        callback();
      }
    };
    const validMail = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号码或邮箱不能为空"));
      } else {
        if (isvalidPhone(value) || validatEmail(value)) {
          this.setFormType(value);
          callback();
        } else {
          callback(new Error("请输入正确格式手机号码或邮箱"));
        }
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      loginList: [
        require("images/home/login_banner.jpg"),
        require("images/home/login_banner1.jpg")
      ],
      loading: false,
      // 判断是登录还是注册
      isLogin: true,
      // 是否账号密码登录
      isAccount: true,
      // 登录类型判断
      loginIndex: 0,
      ruleForm: {
        password: "",
        username: "",
        account: "",
        code: "",
        rememberMe: false
      },
      rules: {
        username: [{ validator: validateName, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "input" }],
        account: [
          { required: true, message: "请输入手机号码或邮箱", trigger: "blur" },
          { required: true, validator: validMail, trigger: "blur" }
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }]
      },
      // 发送登录验证码
      disLoginBtn: false,
      countLogin: 60,
      loginTimer: null,
      // 注册
      // 发送验证码
      disableBtn: false,
      countdown: 60,
      timer: null,
      redirect: null,
      code: null,
      state: null,
      isPwd: true,
      isPhoneLogin: true,
      codeUrl: "",
      postUrl: "",
      checkedUrl: ""
    };
  },
  beforeRouteEnter(to, from, next) {
    let query = to.query;
    if (query && query.state && query.code) {
      let code = query.code,
        state = query.state;
      console.log("code", code, state, state.indexOf("QQ"));
      if (state == "userCenter")
        next("/userCenter?state=userCenter&code=" + query.code);
      else if (state.indexOf("WX") > -1 || state.indexOf("QQ") > -1) {
        let url = window.configData.VUE_APP_BASEURL + "/auth/qq";
        if (state.indexOf("WX") > -1)
          url = window.configData.VUE_APP_BASEURL + "/auth/wechat";
        axios({
          method: "post",
          url: url,
          data: { code, state, thirdChannel: state }
        })
          .then(response => {
            let res = response && response.data;
            if (res.code && res.code == 200 && res.data && res.data.token) {
              next(vm => {
                vm.$store.dispatch("user/setUserInfo", res.data).then(() => {
                  next("/home");
                });
              });
            } else next("/login");
          })
          .catch(err => {
            console.log("err", err.response);
            let errData =
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message
                ? err.response.data.message
                : state.indexOf("QQ") > -1
                ? "该QQ未绑定"
                : "该微信未绑定";
            Message.warning(errData);
            setTimeout(() => {
              next("/login");
            }, 300);
          });
      }
    } else next();
  },
  created() {
    this.getCookie();
  },
  computed: {
    loginTxt() {
      if (this.countLogin == 60 || this.countLogin == 0) return "发送验证码";
      else return `${this.countLogin}秒后发送`;
    },
    pwdType() {
      return this.isPwd ? "password" : "text";
    },
    codeText() {
      return this.isPhoneLogin ? "请输入手机号" : "请输入邮箱";
    }
    // checkedUrl() {
    //   return this.isPhoneLogin ? "/auth/platform/phone/login/send/" : "/auth/platform/email/login/send/";
    // }
  },
  mounted() {
    if (this.$route.query.redirect) {
      this.redirect = this.$route.query.redirect;
    }
  },
  methods: {
    // 密码明文
    changePwd() {
      this.isPwd = !this.isPwd;
    },
    // 切换验证码登录方式
    changeCodeLogin() {
      if (!this.loginTimer && this.countLogin == 60) {
        this.isPhoneLogin = !this.isPhoneLogin;
        this.$refs.ruleForm.clearValidate();
        this.ruleForm = this.resetForm();
        return;
      } else this.$message.warning("验证码发送中，请勿切换登录方式");
    },
    goHome() {
      this.$router.replace("/");
    },
    // 切换登录
    changeLogin(index) {
      if (this.loginIndex == index) return;
      this.loginIndex = index;
      this.$refs.ruleForm.clearValidate();
      if (this.loginIndex == 0) this.getCookie();
    },
    // 检验是否记住密码
    getCookie() {
      const username = Cookies.get("username");
      let password = Cookies.get("password");
      const rememberMe = Cookies.get("rememberMe");
      password = password === undefined ? this.ruleForm.password : password;
      this.ruleForm = {
        username: username === undefined ? this.ruleForm.username : username,
        password: password,
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      };
    },
    // 忘记密码
    goforgotPwd() {
      this.$router.push("forgetPwd");
    },
    // 发送登录验证码
    sendLoginCode() {
      if (this.checkAcount(this.ruleForm.account)) {
        post(this.checkedUrl + this.ruleForm.account)
          .then(res => {
            if (res.code == 200 && res.data) {
              // post(this.codeUrl + this.ruleForm.account)
              //   .then(res => {
              //     if (res && res.code == 200 && res.data) {
              this.disLoginBtn = true;
              this.countLogin = 59;
              this.$message.success("验证码发送成功");
              if (!this.loginTimer) {
                this.loginTimer = setInterval(() => {
                  // 判断剩余秒数
                  if (this.countLogin == 0) {
                    // 清除定时器和复原按钮
                    clearInterval(this.loginTimer);
                    this.loginTimer = null;
                    this.disLoginBtn = false;
                    this.countLogin = 60;
                  } else {
                    this.countLogin--;
                  }
                }, 1000);
              }
              //   } else this.$message.success("验证码发送失败");
              // })
              // .catch(err => {
              //   this.$message.warning(err.message);
              // });
            } else {
              let text = this.isPhoneLogin ? "该手机号不存在" : "该邮箱不存在";
              this.$message.warning(text);
            }
          })
          .catch(err => {
            let message =
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : "账号不存在";
            this.$message({
              message,
              type: "warning"
            });
          });
      }
    },
    // 登录
    submitForm(formName) {
      console.log("submitForm", this.ruleForm, this.loginIndex);
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.ruleForm.rememberMe) {
            Cookies.set("username", this.ruleForm.username, {
              expires: this.$config.passCookieExpires
            });
            Cookies.set("password", this.ruleForm.password, {
              expires: this.$config.passCookieExpires
            });
            Cookies.set("rememberMe", this.ruleForm.rememberMe, {
              expires: this.$config.passCookieExpires
            });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove("rememberMe");
          }
          console.log("username", this.ruleForm);
          // this.$router.replace("/");
          let data = {
            isPhone: false, //是否手机登录
            isPhonePassword: false //是否手机密码登录
          };
          if (this.loginIndex == 0) {
            //使用正则判断是否为11位手机号
            data.password = this.ruleForm.password;
            if (/^1[34578]\d{9}$/.test(this.ruleForm.username)) {
              data.isPhone = true;
              data.phone = this.ruleForm.username;
              data.isPhonePassword = true;
            } else {
              data.username = this.ruleForm.username;
            }
          } else {
            data.isPhone = true;
            data.isEmail = this.isPhoneLogin ? false : true;
            data.phone = this.ruleForm.account;
            data.code = this.ruleForm.code;
          }
          this.$store
            .dispatch("user/login", data)
            .then(res => {
              if (res) {
                this.loading = false;
                this.$store.dispatch("user/getInCompleteNum");
                this.$message({
                  message: "登录成功",
                  type: "success"
                });
                this.$store.dispatch("user/getUserInfo");
                this.$store.dispatch("user/getUserVip");
                // this.$router.replace({ path: this.redirect || "/" });
                setTimeout(() => {
                  this.$router.replace("/");
                }, 500);
                this.openServer({
                  senderUserId: res.authInfo.userId,
                  username: res.authInfo.nickName
                });
              }
            })
            .catch(err => {
              let message =
                err.response && err.response.data && err.response.data.message
                  ? err.response.data.message
                  : "用户名或密码错误";
              this.$message({
                message,
                type: "warning"
              });
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 跳转注册
    goRegister() {
      this.$router.push("/register");
    },
    checkAcount(value) {
      if (!value) {
        this.$message({
          message: "请输入手机号码或邮箱",
          duration: 3 * 1000
        });
        return false;
      } else {
        if (validatEmail(value) || isvalidPhone(value)) {
          this.setFormType(value);
          return true;
        } else {
          this.$message({
            message: "请输入正确格式手机号码或邮箱",
            duration: 3 * 1000
          });
          return false;
        }
      }
    },
    setFormType(value) {
      this.codeUrl = isvalidPhone(value)
        ? "/auth/phone/send/"
        : "/auth/email/send/";
      this.checkedUrl = isvalidPhone(value)
        ? "/auth/phone/login/send/"
        : "/auth/email/login/send/";
      this.isPhoneLogin = isvalidPhone(value) ? true : false;
      console.log("sendCode", this.codeUrl, this.isPhoneLogin);
    },

    resetForm() {
      return {
        password: "",
        username: "",
        account: "",
        code: "",
        rememberMe: false
      };
    },
    loginCode(type) {
      if (type == "QQ") {
        window.location.href =
          "https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=102041349&state=QQ&redirect_uri=https%3A%2F%2Fdendralink.com%2F%23%2Flogin";
      } else {
        window.location.href =
          "https://open.weixin.qq.com/connect/qrconnect?appid=wx0ef76ac160550f8b&redirect_uri=https%3A%2F%2Fdendralink.com%2F%23%2Flogin&response_type=code&scope=snsapi_login&state=WX#wechat_redirect";
      }
    }
  },
  destroyed() {
    // if (this.loginTimer) {
    //   clearInterval(this.loginTimer);
    //   this.loginTimer = null;
    //   this.disLoginBtn = false;
    //   this.countLogin = 60;
    // }
  },
  watch: {
    // $route: {
    //   handler: function(route) {
    //     this.redirect = route.query && route.query.redirect;
    //     this.code = (route.query && route.query.code) || ""; // 'pm02_j6FTqjLJRWefdJmOfqyyEw'
    //     this.state = (route.query && route.query.state) || "";
    //     if (this.code && this.state && this.state !== "userCenter") {
    //       if (this.state == "userCenter") {
    //         this.$router.replace({
    //           name: "userCenter",
    //           query: {
    //             code: this.code,
    //             state: "userCenter"
    //           }
    //         });
    //         return;
    //       }
    //       const data = {
    //         code: this.code,
    //         state: this.state,
    //         thirdChannel: this.state
    //       };
    //       this.$store
    //         .dispatch("user/LoginCode", data)
    //         .then(res => {
    //           this.openServer({
    //             senderUserId: res.authInfo.userId,
    //             username: res.authInfo.nickName
    //           });
    //           if (res.token) {
    //             this.$store.dispatch("user/getInCompleteNum");
    //             this.$router.replace({ path: "/home" });
    //           }
    //         })
    //         .catch(() => {
    //           if (this.code) {
    //             this.$router.push({ query: {} }); // 删除页面参数
    //           }
    //         });
    //     }
    //   },
    //   immediate: true
    // }
  }
};
</script>
<style lang="stylus" scoped>
@import '~@/assets/styl/login'

.login
  position relative

  .login-type-box
    margin-top 24px
    font-weight 600

  .back
    margin 0px 0 16px 0
    height 24px
    font-size 14px
    color #222
    font-weight 500
    justify-content flex-start
    cursor pointer

    .back-name
      height 100%

    img
      width 24px
      height 24px
      margin-right 8px

  .register-title
    margin-bottom 36px
    font-size 18px
    color #222
    font-weight 500
    text-align center

  .regist-desc
    margin-bottom 40px
    font-size 14px
    color #999

  .goHome
    position absolute
    top 23px
    left 20px
    font-size 38px
    color #797979
    cursor pointer

.login-register
  display flex
  justify-content flex-start

  .login-code-text
    cursor pointer

.login-icon-box
  display flex
  align-items center
  justify-content center
  color #fff

  .flex-columm
    cursor pointer

  img
    width 32px

.wx-login-box
  height 400px

iframe
  position fixed
  top 50%
  left 50%
  width 350px
  height 400px
  background #fff
  overflow hidden
  transform translate(-50%, -50%)
  z-index 100
</style>
